import React , {useState , useEffect} from "react";
import "../../../assets/js/script";
import axios from "axios";
import ContactForm from "../ContactForm";
function ContactInfo() {
    const [items, setitems] = useState([]);
    useEffect(() => {
        axios.get("https://9jeofvls.directus.app/items/PageInfo/2d6e0b1d-232f-4d91-907d-66be338dff31").then(
            res => setitems(res.data.data)
        )
    }, [])
    return (
        <>
            <div className="relative z-50">
                <div className="mt-0  sm:mt-10  relative">
                    {/* <div className="w-[90%] max-w-screen-xl rounded-3xl mx-auto relative z-50 bg-[#172940] flex flex-col md:flex-row border-2 border-linkColor">
                        <div className="w-full md:w-1/2 mt-10 md:mt-0  flex flex-col justify-center items-center space-y-3">
                            <div className="w-[80%] text-white font-press-start-lato">
                                <a href="https://goo.gl/maps/YLVE5vb5RLJgMsNm8" className="">
                                    <p className="flex items-center justify-center md:justify-start mb-4 hover:scale-105 transition-all duration-300 ease-linear">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 mr-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                                        </svg>

                                        Abovyan 1/8 of the Constitution
                                    </p>
                                </a>
                            </div>
                            <div className="w-[80%]  text-white font-press-start-lato">
                                <a href="mailto:info@abov.am">
                                    <p className="flex items-center justify-center md:justify-start mb-4 hover:scale-105 transition-all duration-300 ease-linear">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
                                            className="w-6 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                            </path>
                                        </svg>
                                        info@abov.am
                                    </p>
                                </a>
                            </div>
                            <div className="w-[80%] text-white font-press-start-lato">
                                <a href="tel:+37493393345">
                                    <p className="flex items-center justify-center md:justify-start mb-4 hover:scale-105 transition-all duration-300 ease-linear">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                            className="w-6 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor"
                                                d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                            </path>
                                        </svg>
                                        +374 93 39 33 45
                                    </p>
                                </a>
                            </div>
                            <div className="flex space-x-5  justify-center mt-10 py-5 w-full">
                                <a href="#!"
                                    className="text-white border-2 border-linkColor w-12 h-12 rounded-full flex justify-center items-center transition-all duration-300 hover:bg-gray-900 hover:scale-110">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" className="w-2.5"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                        <path fill="currentColor"
                                            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
                                        </path>
                                    </svg>
                                </a>
                                <a href="#!"
                                    className="text-white border-2 border-linkColor w-12 h-12 rounded-full flex justify-center items-center transition-all duration-300 hover:bg-gray-900 hover:scale-110">
                                    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" className="w-3.5"
                                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor"
                                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
                                        </path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="w-full  md:w-1/2 ">
                            <div className="containerS font-press-start-lato">
                                <form className="form-container">
                                    <div className="headline"><span className="text-white text-3xl">Contact Us</span></div>
                                    <div className="form-line">
                                        <input type="text" className="form-input" required />
                                        <label className="">Name *</label>
                                        <div className="error-label">Field is required!</div>
                                        <div className="check-label"></div>
                                    </div>
                                    <div className="form-line">
                                        <input type="email" className="form-input" required />
                                        <label>Your email *</label>
                                        <div className="error-label">Field is required!</div>
                                        <div className="check-label"></div>
                                    </div>
                                    <div className="form-line">
                                        <textarea className="form-input" required></textarea>
                                        <label>Message *</label>
                                        <div className="check-label"></div>
                                        <div className="error-label">Field is required!</div>
                                    </div>

                                    <input type="button" className="form-button rounded-3xl" value="Submit" />
                                </form>
                            </div>
                        </div>
                    </div> */}
                  <ContactForm />
                </div>
            </div>
        </>
    )
}
export default ContactInfo