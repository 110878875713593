import React from "react";
import BottomHeader from "./Components/BottomHeader";
import PortfolioContent from "./Components/Portfolio/PortfolioContent";
function Portfolio(){
    return(
        <>
            <BottomHeader/>
            <PortfolioContent />
        </>
    )
}
export default Portfolio