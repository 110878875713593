import React from "react";
import BlogContent from "./Components/Blog/BlogContent";
import BottomHeader from "./Components/BottomHeader";

function Blog(){
    return(
        <>
            <BottomHeader />
            <BlogContent />
        </>
    )
}
export default Blog