import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AbovLogo from "../../assets/img/logo/abov.svg";

function Header() {
  const [open, setOpen] = React.useState(false);
  const [flyer, setFlyer] = React.useState(false);
  const [flyerTwo, setFlyerTwo] = React.useState(false);
  return (
    <>
      <div className="relative bg-white border-b-2 border-gray-100 z-50">
        <div className="max-w-screen-xl Menu_Content mx-auto ">
          <div className="flex justify-between items-center  py-2 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link to="/">
                <img src={AbovLogo} className={'w-24'} />
              </Link>
            </div>
            <div className=" bg-white md:hidden">
              <button
                type="button"
                className="rounded-md  inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setOpen(!open)}
              >

                {/* <span className="sr-only">Open menu</span> */}
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10 relative z-50">

            
              <Link
                to="/about"
                className="header-link"

              >
                About
              </Link>
              <Link
                to="/services"
                className="header-link"

              >
                Services
              </Link>
              <Link
                to="/projects"
                className="header-link"

              >
                Projects 
              </Link>
              <Link
                to="/contact"
                className="header-link"

              >
                Contact
              </Link>


            </nav>

          </div>
        </div>


        <div
          className={
            open
              ? "opacity-100 scale-100 transition ease-out duration-200 absolute top-0 inset-x-0 p-2  transform origin-top-right md:hidden"
              : "opacity-0 scale-95 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          }
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                <Link to={'/'}>
                <img
                    className="h-8 w-auto"
                    src={AbovLogo}
                    alt="Workflow"
                  />
                </Link>
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => setOpen(!open)}
                  >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>

            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-1 gap-y-4 gap-x-8">
               
                <Link
                  to="/about"
                  className="header-link"

                >
                  About
                </Link>
                <Link
                  to="/services"
                  className="header-link"

                >
                  Services
                </Link>
                <Link
                  to="/projects"
                  className="header-link"

                >
                  Projects 
                </Link>
                <Link
                  to="/contact"
                  className="header-link"

                >
                  Contact
                </Link>
              </div>

            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default Header