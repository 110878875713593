import React, { useEffect, useState } from "react";
import BottomHeader from "./Components/BottomHeader";
import HomeAbout from "./Components/Home/HomeAbout";
import HomeAfterRotate from "./Components/Home/homeAfterrotate";
import HomeCounter from "./Components/Home/HomeCounter";
import HomeSceens from "./Components/Home/homeSceens";
import AbovWhite from "../assets/img/logo/LLC-White.svg";
import axios from "axios";
function Home() {

 

    return (
        <>
            {/* {
                items && items.map((item) => {
                    if (item.slug == "home")

                        return
                })
            } */}
    <BottomHeader />

            <HomeSceens />
            <HomeCounter />
            <HomeAfterRotate />
        </>
    )
}
export default Home