import React , {useState , useEffect} from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import KaassPng from '../../../assets/img/home/kaass.png';
import axios from "axios";
function HomeSceens() {
    const [items, setitems] = useState([]);
    useEffect(() => {
        axios.get(`https://9jeofvls.directus.app/items/Projects`).then(
            res => setitems(res.data.data)
        )
    }, [])
    let settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots:true,
         appendDots: dots => (
        <div
          style={{
            borderRadius: "10px",
            padding: "10px",
            bottom:"-60px",
            height:"40px",
            fontSize:"12px"
        }}
        >
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
    };

    return (
        <>
            <div className="Section_for_round">
                <div className="w-[90%] max-w-screen-xl mx-auto">
                    <Slider {...settings} className={'bg-white rounded-3xl'}>
                        
                        {
                            items && items.map((itm , index) => {
                                return  <div className="rounded-3xl">
                                <div className="flex w-[90%] mx-auto md:w-full  flex-wrap p-10 md:flex-nowrap   space-x-0 md:space-x-6">
                                    <div className="md:w-1/2 h-72 md:h-auto flex flex-col justify-evenly">
                                        <h1 className="text-linkColor font-bold text-xl md:text-4xl font-press-start">{itm.project_title}</h1>
                                        <p className="text-black font-bold text-1.5xl text-justify font-press-start-lato">
                                        {itm.project_text}
                                        </p>
                                        <a href={'itm.project_url'} className="link-button-dark">See More</a>
                                    </div>
                                    <div className="md:w-1/2 mt-10 md:mt-0 h-72 rounded-3xl overflow-hidden">
                                        <img src={`https://9jeofvls.directus.app/assets/${itm.project_image}`} alt={'Kaass Website Png Full Height'} className={'hover:translate-y-[-80%] ease-linear transition-all duration-[3s]'} />
                                    </div>
                                </div>
                            </div>
                            })
                        }

                       
                    </Slider>
                </div>
            </div>
        </>
    )
}
export default HomeSceens