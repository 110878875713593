import React from "react";
import BottomHeader from "./Components/BottomHeader";
import ServiceHeader from "./Components/Service/ServiceHeader";
import ServiceMap from "./Components/Service/ServiceMap";

function Service() {
    
    return (
        <>

            <BottomHeader />
            <ServiceMap />
        </>
    )
}
export default Service