import React , {useState ,useEffect} from "react";
import AboutImg from "../../../assets/img/about/company.jpg";
import axios from "axios";
function AboutPart() {
    const [items, setitems] = useState([]);
    useEffect(() => {
        axios.get(`https://9jeofvls.directus.app/items/About`).then(
            res => setitems(res.data.data)
        )
    }, [])
    return (
        <>
        {
            items && items.map((itm , index) => {
                if(index % 2 == 0){
                    return <div className="w-[90%] max-w-screen-xl flex flex-col md:flex-row mx-auto pb-5 md:pb-0 my-10 bg-[#F0F4F9] rounded-3xl  overflow-hidden">
                    <div className="w-[90%] mx-auto lg:w-3/6 py-6 lg:p-12">
                        <h1 className="About_Title">{itm.about_title}</h1>
                        <p className="About_Desc" dangerouslySetInnerHTML={{__html: itm.about_description}} />
                    </div>
                    <div className="w-[90%] mx-auto lg:w-3/6 bg-[#172940] overflow-hidden flex justify-center items-center">
                        <img src={`https://9jeofvls.directus.app/assets/${itm.about_image}`} className={'w-96 h-96 '} />
                    </div>
                </div>
                }else{
                    return  <div className="w-[90%] max-w-screen-xl flex flex-col md:flex-row mx-auto pb-5 md:pb-0 my-10 bg-[#F0F4F9] rounded-3xl  overflow-hidden">
                    <div className="w-[90%] mx-auto lg:w-3/6 bg-[#172940] overflow-hidden flex justify-center items-center">
                        <img src={`https://9jeofvls.directus.app/assets/${itm.about_image}`} className={'w-96 h-96 '} />
                    </div>
                    <div className="w-[90%] mx-auto lg:w-3/6 py-6 lg:p-12">
                        <h1 className="About_Title">{itm.about_title}</h1>
                        <p className="About_Desc" dangerouslySetInnerHTML={{__html: itm.about_description}} />
                    </div>
    
                </div>
                }
            })
        }
            

           


        </>
    )
}
export default AboutPart