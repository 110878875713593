import React , {useState,useEffect} from "react";
import AboutHeader from "./Components/About/AboutHeader";
import AboutOpinion from "./Components/About/AboutOpinion";
import AboutPart from "./Components/About/AboutPart";
import BottomHeader from "./Components/BottomHeader";
import AbovWhite from "../assets/img/logo/LLC-White.svg";
import axios from "axios";
function About(){




    return(
        <>

        <BottomHeader  />
          {/* <AboutHeader /> */}
          <AboutPart />
          <AboutOpinion />
        </>
    )
}
export default About