import React from "react";
import BottomHeader from "./Components/BottomHeader";
import ContactPart from "./Components/Contact/ContactContent";
import ContactInfo from "./Components/Contact/contactinfo";

function Contact(){
    return(
        <>
            <BottomHeader />

           <ContactInfo />  
           <ContactPart />
          
        </>
    )
}
export default Contact