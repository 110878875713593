import React , {useState , useEffect} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import BottomHeader from "../BottomHeader";
function ServiceSingle() {
    const {id} = useParams();
    // let pth = window.location.pathname;
    // console.log(pth)
    // let k = pth.lastIndexOf('/')
    
    // let newPth = pth.slice(1, k);
    // console.log(newPth)

    const [items, setitems] = useState([]);
    useEffect(() => {
        axios.get(`https://9jeofvls.directus.app/items/Services/${id}`).then(
            res => setitems(res.data.data)
        )
    }, [])    
    return (
        <>
            <BottomHeader />
            {
                items.current_service && items.current_service.map((itm , index) => {
                    return   <div className="max-w-screen-xl mx-auto my-10 py-5 bg-[#F0F4F9] rounded-3xl px-12 Shadow overflow-auto h-48 sm:h-auto scrollbar-thin  scrollbar-thumb-linkColor scrollbar-thumb  scrollbar-track-transparent scrollbar-thumb-rounded-full   sm:overflow-visible" key={index}>
                    <h1 className="font-bold text-2xl font-press-start">{itm.current_service_title}</h1>
                    <p className="text-justify font-medium font-press-start-lato mt-5" dangerouslySetInnerHTML={{__html: itm.current_service_desc}}  />
                </div>
                }) 
            }



        </>
    )
}
export default ServiceSingle
