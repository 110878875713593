import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import WebImg from '../../../assets/img/service/web.jpg';
import axios from "axios";
function ServiceMap() {

    const [items, setitems] = useState([]);
    useEffect(() => {
        axios.get(`https://9jeofvls.directus.app/items/Services`).then(
            res => setitems(res.data.data)
        )
    }, [])

    return (
        <>
            {
                items && items.map((itm, index) => {
                    if (index % 2 == 0) {
                       return <div className="w-[90%] max-w-screen-xl mx-auto flex flex-wrap justify-center space-x-0  z-20 border my-10 overflow-hidden bg-[#F0F4F9] rounded-3xl">
                       <div className="w-full flex flex-col md:flex-row rounded-3xl">
                           <div className="w-[100%] mx-auto md:w-1/2 px-6 md:px-12 flex flex-col justify-center space-y-10">
                               <h3 className="text-left  font-bold text-3xl font-press-start">{itm.service_name}</h3>
                               <p className=" text-black font-bold text-justify font-press-start-lato text-lg">
                                    {itm.service_description}
                               </p>
                               <Link to={`/serviceSingle/${itm.id}`} className="link-button">See More</Link>
                           </div>
                           <div className="w-[90%] my-6 md:my-0 mx-auto md:w-1/2 bg-[#172940] rounded-3xl md:rounded-none flex justify-center">
                               <img src={`https://9jeofvls.directus.app/assets/${itm.service_image}`} className={'h-48 w-48  md:h-96 md:w-96'} />
                           </div>
                       </div>
                   </div>
                    } else {
                      return  <div className="w-[90%] max-w-screen-xl mx-auto flex flex-wrap justify-center space-x-0  z-20 border my-10 overflow-hidden bg-[#F0F4F9] rounded-3xl">
                            <div className="flex flex-col md:flex-row-reverse rounded-3xl">
                                <div className="w-[100%] mx-auto md:w-1/2 px-6 md:px-12 flex flex-col justify-center space-y-10">
                                    <h3 className="text-left  font-bold text-3xl font-press-start">{itm.service_name}</h3>
                                    <p className=" text-black font-bold text-justify font-press-start-lato text-lg">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <Link to={`/serviceSingle/${itm.id}`} className="link-button">See More</Link>
                                </div>
                                <div className="w-[90%] my-6 md:my-0 mx-auto md:w-1/2 bg-[#172940] rounded-3xl md:rounded-none flex justify-center">
                                    <img src={`https://9jeofvls.directus.app/assets/${itm.service_image}`} className={'h-48 w-48  md:h-96 md:w-96'} />
                                </div>
                            </div>
                        </div>
                    }
               
            })
        }
          
        </>
    )
}
export default ServiceMap