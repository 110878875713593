import React from "react";
import { Link } from "react-router-dom";
import AbovWhite from "../../../assets/img/logo/LLC-White.svg";
function HomeAbout(){
    return(
        <>
             <div className="max-w-screen-xl mx-auto Second__Blog overflow-hidden z-20 border" id="Second__Home__Page_Blog">
            <div
                className="second_for_text relative z-30 md:w-2/4 font-bold sm:p-8 flex flex-col justify-center space-y-8">
                <h1 className="text-linkColor text-2xl xl:text-5xl px-5 lg:text-4xl md:text-3xl sm:text-3xl">We Design, Build and
                    Deliver Software Products</h1>
                <p className="text-md sm:text-xl text-justify font-press-start-lato px-5 text-gray-100">
                    More than 200 companies have trusted Relevant to scale their engineering teams and build web &
                    mobile
                    apps.
                    Our core services:
                </p>
                <div className="flex space-x-8 mx-5">
                    <Link to="/service" className="link-button">Our Services</Link>
                    <Link to="/projects" className="link-button">Projects</Link>
                </div>
            </div>
            <div className="second_for_image relative z-30 md:w-2/4 flex justify-center">
                <img src={AbovWhite} className="w-72 md:w-auto" alt="" />
            </div>
        </div>
        </>
    )
}
export default HomeAbout